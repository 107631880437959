<template>
  <div class="container-fluid">
<!--     <div class="bg-white pt-28 pb-24 mb-4 w-100 rounded text-center">
      <span class="fs25 fw700">
        Due: {{ amounts.due | currency('inr') }}
      </span>
    </div> -->

    <div v-if="this.windowWidth > 767">
      <b-table
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :busy="isBusy"
        sticky-header
        no-border-collapse
        responsive
        class="light-header cod"
        id="cod-table"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(paidOn)="data">
          <span>
            {{ data.item.paidOn | date }}
          </span>
        </template>
        <!-- A custom formatted data column cell for field 'Action' -->
        <template v-slot:cell(action)="data">
          <b-button @click="downloadOne(data.item.id)" v-if="data.item.id != 'Expected'" variant="primary btn-xs">
            <i class="icon-download fs13 mr-6"></i>
            Download
          </b-button>
        </template>
      </b-table>
    </div>

    <ul class="table-cards table-cards--shipments d-flex d-md-none" v-else>
      <li class="" v-for="item in items" :key="item.id">
        <div class="d-flex w-100 align-items-end justify-content-between">
          <span class="d-block fw500 pb-8 cp">
            ID -
            {{ item.id }}
          </span>
          <b-button @click="downloadOne(item.id)" v-if="item.id != 'Expected'" variant="primary btn-xs">
            <i class="icon-download fs12 mr-6"></i>
            Download
          </b-button>
        </div>
        <div class="d-flex w-100 align-items-end justify-content-between mt-12">
          <div class="d-flex flex-column">
            <span class="fs12 lh16 text-gray-600">
              UTR:
            </span>
            <span>
              {{ item.utr }}
            </span>
          </div>

          <div class="d-flex flex-column">
            <span class="fs12 lh16 text-gray-600">
              Paid on:
            </span>
            <span>
              {{ item.paidOn | date }}
            </span>
          </div>
          <span>
            ₹
            {{ item.amount }}
          </span>
        </div>
      </li>
    </ul>

    <div class="pagination-bar">
      <div class="container-fluid">
        <div class="row">
          <div class="col-4">
            <div class="d-inline-flex wsnw align-items-center">
              <div class="d-block w50px">
                <multiselect
                  class="pagination-items__pp"
                  v-model="perPage"
                  :searchable="false"
                  :show-labels="false"
                  :allow-empty="false"
                  :options="pageOptions"
                  @input="setPageNumber"
                ></multiselect>
              </div>
              <span
                class="pl-8 fs12 lh24 text-gray-600 pagination-items"
              ></span>
            </div>
          </div>
          <div class="col-8 text-right">
            <div class="d-inline-flex ml-auto wsnw align-items-center">
              <p class="fs12 lh24 text-gray-600 pr-14">
                Page: {{ currentPage }}
              </p>

              <b-button-group>
                <b-button
                  @click="prevPage"
                  :disabled="prevDisabled"
                  variant="paginate left"
                >
                  <i class="icon-back fs12"></i>
                </b-button>

                <b-button
                  @click="nextPage"
                  :disabled="nextDisabled"
                  variant="paginate right"
                >
                  <i class="icon-arrow fs12"></i>
                </b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ResultNotFound v-if="items.length == 0 && !isBusy" />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ResultNotFound from "@/components/ResultNotFound.vue";

export default {
  name: "CodTable",
  data() {
    return {
      pageOptions: this.$store.state.pageOptions,
      totalRows: 1,
      currentPage: 1,
      perPage: this.$store.state.perPage,
      totalPages: 1,
      isBusy: true,
      prevDisabled: true,
      nextDisabled: false,
      startPage: 1,
      pagesArr: [],
      pagination: {},
      items: [],
      amounts: {},
      fields: [
        { key: "id", label: "ID", sortable: true },
        { key: "amount", sortable: true },
        { key: "paidOn", sortable: true, tdClass: "wsnw" },
        { key: "utr", label: "UTR", sortable: true },
        { key: "action", label: "", tdClass: "text-left, pl-0" },
      ],
    };
  },
  components: {
    Multiselect,
    ResultNotFound
  },
  created() {
    this.getItems()
  },
  methods: {
    async getItems() {
      try {
        this.isBusy = true
        const response = await this.axios.get(`/cod?page=${this.currentPage}&offset=${this.perPage}`)
        this.items = response.data.codData
        this.amounts = response.data.amounts
        this.isBusy = false
        this.totalRows = this.items.length;
        this.pagination = response.data.pagination
        this.setPagination()
        if(this.items.length < this.perPage) {
          this.nextDisabled = true
        }
        this.isBusy = false
      } catch (error) {
        this.isBusy = false
        this.nextDisabled = true
        this.popToast('failed', 'Failed!', error)
      }
    },
     setPagination() {
      this.nextDisabled = this.pagination.next ? false : true
      this.prevDisabled = this.pagination.prev ? false : true
    },
    pushTotalPages() {
      this.pagesArr = [];
      for (var i = 1; i <= this.totalPages; i++) {
        this.pagesArr.push(i);
      }
    },
    setPageNumber() {
      this.currentPage = 1
      this.getItems()
      this.totalPages = Math.ceil(this.totalRows / this.perPage);
      this.pagesArr = [];
      for (var i = 1; i <= this.totalPages; i++) {
        this.pagesArr.push(i);
      }
      this.currentPage = 1;
      this.prevDisabled = true;
      if (this.totalPages == 1) {
        this.nextDisabled = true;
      } else if (this.totalPages > 1) {
        this.nextDisabled = false;
      }
    },
    updatePageNumber() {
      this.totalPages = Math.ceil(this.totalRows / this.perPage);
      this.pagesArr = [];
      for (var i = 1; i <= this.totalPages; i++) {
        this.pagesArr.push(i);
      }
    },
    async downloadOne(id) {
      const res = await this.axios.get(`/remittance/${id}/slip`)
      this.popToast("requested", "Download requested", "Your download should start now.");
      this.download(res.data.path, id+".xlsx")
    },
    nextPage() {
      this.currentPage++;
      this.getItems()
    },
    prevPage() {
      this.currentPage--
      this.getItems()
    },
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  },
  mounted() {
    this.totalRows = this.items.length;
    this.totalPages = Math.ceil(this.totalRows / this.perPage);
    for (var i = 1; i <= this.totalPages; i++) {
      this.pagesArr.push(i);
    }
  },
  watch: {
    currentPage: function() {
      this.prevDisabled = false;
      this.nextDisabled = false;

      if (this.currentPage == 1) {
        this.prevDisabled = true;
      }

      if (this.currentPage == this.totalPages) {
        this.nextDisabled = true;
      }
    },
  },
};
</script>
