<template>
	<div class="cod">
		<AppTopbar
		title="COD"
		buttonText="New Order"
		buttonIconClass="icon-add"
		buttonLink="new"
		/>
		<CodTable v-if="show" class="pt-70 pt-md-98" />
		<div v-if="isApproved==0 || isApproved==null || isApproved==2" class="container-fluid pt-70 pt-md-100">
			<div class="card col-md-5 m-auto">
				<div class="card__title">
					<span class="fs18 fw500 d-block">
						Bank Account Information <span class="fs12 text-gray-600">(All the fields are mandatory to fill)</span>
					</span>
				</div>
				<div class="card__content">
					<div v-if="isApproved == 2">
						<div class="col-md-12 mb-10">
							<b-form-group class="mb-10 text-gray-600" label="Verify Amount"label-for="amount">
								<b-form-input
								id="amount"
								type="number"
								max="500"
								required
								placeholder="1.49"
								v-model="amount"
								></b-form-input>
							</b-form-group>
							<span class="text-red">We have deposited a small amount for bank account verification.</span>
						</div>
						<div class="col-md-12">
							<b-form-group class="mb-10">
								<b-button @click="veriyBank" :disabled="submitting" variant="primary px-20">
									Verify <b-spinner v-if="submitting" class="ml-10"></b-spinner>
								</b-button> 
							</b-form-group>
						</div>
					</div>
					<b-form v-else @submit.prevent="submitBank">
						<div class="col-md-12">
							<b-form-group class="mb-15 text-gray-600" label="Account Name *"label-for="accountName">
								<b-form-input
								id="accountName"
								type="text"
								maxlength="45"
								required
								placeholder="Account Name"
								v-model="form.accountName"
								></b-form-input>
							</b-form-group>
						</div>
						<div class="col-md-12">
							<b-form-group class="mb-15 text-gray-600" label="Account Number *"label-for="accountNo">
								<b-form-input
								id="accountNo"
								type="text"
								maxlength="16"
								required
								placeholder="Account Number"
								v-model="form.accountNo"
								></b-form-input>
							</b-form-group>
						</div>
						<div class="col-md-12">
							<b-form-group class="mb-15 text-gray-600" label="Bank Name *"label-for="bankName">
								<b-form-input
								id="bankName"
								type="text"
								maxlength="55"
								required
								placeholder="Bank Name"
								v-model="form.bankName"
								></b-form-input>
							</b-form-group>
						</div>
						<div class="col-md-12">
							<b-form-group class="mb-15 text-gray-600" label="IFSC CODE *"label-for="ifscCode">
								<b-form-input
								id="ifscCode"
								maxlength="11"
								type="text"
								required
								placeholder="IFSC CODE"
								v-model="form.ifscCode"
								></b-form-input>
							</b-form-group>
						</div>
						<div class="col-md-12">
							<b-form-group class="mb-10">
								<b-button type="submit" :disabled="submitting" variant="primary px-20 btn-block">
									Submit <b-spinner v-if="submitting" class="ml-10"></b-spinner>
								</b-button> 
							</b-form-group>
						</div>
					</b-form>
				</div>
			</div>
		</div>
		<div v-if="isApproved == 1 && show==false" class="container-fluid pt-70 pt-md-100">
			<div class="card col-md-6 m-auto">
				<div class="card__title">
					<span class="fs18 fw500  d-block">
						Profile Password
					</span>
				</div>
				<div class="card__content">
					<span class="fs12 text-gray-600 pb-10 d-block">
						Please provide profile password to access COD page
					</span>
					<b-form @submit.prevent="validate" class="row">
						<div class="col-md-6 col-12">
							<b-form-group class="mb-10">
								<b-form-input
								id="pin-code"
								type="password"
								required
								placeholder="Profile Password"
								v-model="password"
								></b-form-input>
							</b-form-group>
						</div>
						<div class="col-3">
							<b-form-group class="mb-10">
								<b-button type="submit" :disabled="submitting" variant="primary px-20">
									Verify <b-spinner v-if="submitting" class="ml-10"></b-spinner>
								</b-button> 
							</b-form-group>
						</div>
					</b-form>
					<b-link @click="forgotPassword">Forgot your password?</b-link>
				</div>
			</div>
		</div>
		<div v-if="isApproved == 3" class="container-fluid pt-70 pt-md-100">
			<div class="card col-md-6">
				<div class="card__title">
					<span class="fs18 fw500  d-block">
						Pending bank approval
					</span>
				</div>
				<div class="card__content">
					<p>Your Bank Account approval is pending. Please contact to support for more information.</p>
					Please <b-link to="/support">Click Here</b-link>.
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AppTopbar from "@/components/layout/AppTopbar.vue";
import CodTable from "@/components/CodTable.vue";

export default {
	name: "Cod",
	title: "Shyplite - COD",
	components: {
		AppTopbar,
		CodTable,
	},
	data() {
		return {
			show 		: false,
			submitting 	: false,
			password 	: '',
			form 		: {},
			amount 		: null,
			isApproved  : this.$store.getters.user.isApproved
		}
	},
	created() {
		if(this.$store.getters.user.childRole == 'OPERATIONS') {
			this.$router.push('/unauthorized')
		}
		if(this.isApproved == 3) {
			this.checkStatus()
		}
	},
	methods: {
		async validate() {
			this.submitting = true
			const res = await this.axios.post('/settings/profile/verifyprofile', {profile_password: this.password})
			if(res.data.success == true) {
				this.show = true
			}else{
				this.popToast("failed", "Failed", res.data.message);
			}
			this.submitting = false
		},

		async forgotPassword() {
			if(confirm('Are you sure you want to reset profile password?')){
				try {
					const res = await this.axios.post('/forgotprofilepassword')
					console.log(res.data)
					if(res.data.success == true) {
						this.popToast("booked", "Mail sent!", res.data.message);
					}else{
						this.popToast("failed", "Failed", res.data.message);
					}
				} catch(e) {
					console.log(e);
				}
			}
		},

		async checkStatus() {
			const res = await this.axios.get('/settings/profile')
			this.$store.commit('setUser', res.data.seller)
		},

		async submitBank() {
			this.submitting = true
			const res = await this.axios.post('/register/newbank', this.form)
			if(res.data.success == true) {
				this.$store.commit('setUser', {'isApproved':res.data.sellerDetail.isApproved, 'bankID': res.data.sellerDetail.bankID})
			}else{
				this.popToast("failed", "Failed", res.data.error);
			}
			this.submitting = false
		},


		async veriyBank() {
			this.submitting = true
			const res = await this.axios.post('/bank/verification', {bankDetailID: this.bankDetailID, amount: this.amount})
			if(res.data.success == true) {
				this.$store.commit('setUser', {'isApproved':res.data.sellerDetail.isApproved})
			}else{
				this.popToast("failed", "Failed", res.data.message);
			}
			this.submitting = false
		}
	},
	computed: {
		bankDetailID() {
			return this.$store.getters.user.bankID
		}
	}
};
</script>
