var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      this.windowWidth > 767
        ? _c(
            "div",
            [
              _c("b-table", {
                staticClass: "light-header cod",
                attrs: {
                  items: _vm.items,
                  fields: _vm.fields,
                  "per-page": _vm.perPage,
                  busy: _vm.isBusy,
                  "sticky-header": "",
                  "no-border-collapse": "",
                  responsive: "",
                  id: "cod-table"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "table-busy",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center text-danger my-2" },
                            [
                              _c("b-spinner", { staticClass: "align-middle" }),
                              _c("strong", [_vm._v("Loading...")])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "cell(paidOn)",
                      fn: function(data) {
                        return [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("date")(data.item.paidOn)) +
                                " "
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(action)",
                      fn: function(data) {
                        return [
                          data.item.id != "Expected"
                            ? _c(
                                "b-button",
                                {
                                  attrs: { variant: "primary btn-xs" },
                                  on: {
                                    click: function($event) {
                                      return _vm.downloadOne(data.item.id)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon-download fs13 mr-6"
                                  }),
                                  _vm._v(" Download ")
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3073855193
                )
              })
            ],
            1
          )
        : _c(
            "ul",
            {
              staticClass: "table-cards table-cards--shipments d-flex d-md-none"
            },
            _vm._l(_vm.items, function(item) {
              return _c("li", { key: item.id }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex w-100 align-items-end justify-content-between"
                  },
                  [
                    _c("span", { staticClass: "d-block fw500 pb-8 cp" }, [
                      _vm._v(" ID - " + _vm._s(item.id) + " ")
                    ]),
                    item.id != "Expected"
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "primary btn-xs" },
                            on: {
                              click: function($event) {
                                return _vm.downloadOne(item.id)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "icon-download fs12 mr-6" }),
                            _vm._v(" Download ")
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex w-100 align-items-end justify-content-between mt-12"
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                        _vm._v(" UTR: ")
                      ]),
                      _c("span", [_vm._v(" " + _vm._s(item.utr) + " ")])
                    ]),
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                        _vm._v(" Paid on: ")
                      ]),
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm._f("date")(item.paidOn)) + " ")
                      ])
                    ]),
                    _c("span", [_vm._v(" ₹ " + _vm._s(item.amount) + " ")])
                  ]
                )
              ])
            }),
            0
          ),
      _c("div", { staticClass: "pagination-bar" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4" }, [
              _c(
                "div",
                { staticClass: "d-inline-flex wsnw align-items-center" },
                [
                  _c(
                    "div",
                    { staticClass: "d-block w50px" },
                    [
                      _c("multiselect", {
                        staticClass: "pagination-items__pp",
                        attrs: {
                          searchable: false,
                          "show-labels": false,
                          "allow-empty": false,
                          options: _vm.pageOptions
                        },
                        on: { input: _vm.setPageNumber },
                        model: {
                          value: _vm.perPage,
                          callback: function($$v) {
                            _vm.perPage = $$v
                          },
                          expression: "perPage"
                        }
                      })
                    ],
                    1
                  ),
                  _c("span", {
                    staticClass: "pl-8 fs12 lh24 text-gray-600 pagination-items"
                  })
                ]
              )
            ]),
            _c("div", { staticClass: "col-8 text-right" }, [
              _c(
                "div",
                {
                  staticClass: "d-inline-flex ml-auto wsnw align-items-center"
                },
                [
                  _c("p", { staticClass: "fs12 lh24 text-gray-600 pr-14" }, [
                    _vm._v(" Page: " + _vm._s(_vm.currentPage) + " ")
                  ]),
                  _c(
                    "b-button-group",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.prevDisabled,
                            variant: "paginate left"
                          },
                          on: { click: _vm.prevPage }
                        },
                        [_c("i", { staticClass: "icon-back fs12" })]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.nextDisabled,
                            variant: "paginate right"
                          },
                          on: { click: _vm.nextPage }
                        },
                        [_c("i", { staticClass: "icon-arrow fs12" })]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _vm.items.length == 0 && !_vm.isBusy ? _c("ResultNotFound") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }